@import "bootstrap/scss/mixins/breakpoints";

$fontWeightBold: 700;
$fontWeightNormal: 400;

h1,
.pot-h1 {
  font-size: 40px;
  font-weight: $fontWeightBold;

  @include media-breakpoint-up(lg) {
    font-size: 56px;
  }
}

h2,
.pot-h2 {
  font-size: 32px;
  font-weight: $fontWeightBold;

  @include media-breakpoint-up(lg) {
    font-size: 40px;
  }
}

h3,
.pot-h3 {
  font-size: 20px;
  font-weight: $fontWeightBold;

  @include media-breakpoint-up(md) {
    font-size: 24px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 28px;
  }
}

h4,
.pot-h4 {
  font-size: 20px;
  font-weight: $fontWeightBold;

  @include media-breakpoint-up(lg) {
    font-size: 24px;
  }
}

.pot-subtitle {
  font-size: 20px;
  font-weight: $fontWeightNormal;

  @include media-breakpoint-up(lg) {
    font-size: 24px;
  }
}

p,
.pot-p {
  font-size: 14px;
  font-weight: $fontWeightNormal;

  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
}

.pot-small-p {
  font-size: 12px;
  font-weight: $fontWeightNormal;

  @include media-breakpoint-up(lg) {
    font-size: 14px;
  }
}

.text-align-center {
  text-align: center;
}
