/*
 * Application global variables.
 */

// Set Font Awesome font path

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

// Font
$defaultFont:
  100% "Montserrat",
  sans-serif;

// ---------------------------------------------------------------------------
// Bootstrap variables
// Override Bootstrap variables here to suite your theme.
// Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss

// Color system

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #0073dd;
$cyan: #17a2b8;
$green: #28a745;
$indigo: #6610f2;
$orange: #e57450;
$orangeTinted: #dd9264;
$orangeLight: #fae3dc;
$orangeLightTinted: #f1d8c9;
$pink: #e83e8c;
$purple: #6f42c1;
$red: #dc3545;
$silvery: #515151;
$teal: #20c997;
$yellow: #ffc107;

$theme-colors: (
  primary: $blue,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800,
);

// Use Bootstrap defaults for other variables, imported here so we can access all app variables in one place when used
// in components.
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

$paddingTopStickyMenu: 15px;
$borderRadiusCard: 30px;
$borderRadiusFormControl: 15px;
$heightFooter: 111px;

$paddingLeftCustomFormControl: 20px;
$heightCustomFormControl: 52px;
$paddingSidePage: 15px;
$maxWidthComponents: 900px;

:root {
  --pl-font-family-montserrat: "Montserrat";
  --pl-orange-500: #ec754e;
  --pl-orange-800: #f06334;
  --pl-gray-450: #606060;
  --pl-gray-500: #484a4c;
}
