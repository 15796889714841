.pot-margin-bottom-10 {
  margin-bottom: 10px;
}

.pot-margin-bottom-20 {
  margin-bottom: 20px;
}

.pot-margin-bottom-30 {
  margin-bottom: 30px;
}

.pot-margin-bottom-40 {
  margin-bottom: 40px;
}

.pot-margin-bottom-50 {
  margin-bottom: 50px;
}

.pot-margin-bottom-60 {
  margin-bottom: 60px;
}

.pot-margin-bottom-100 {
  margin-bottom: 100px;
}

.pot-margin-bottom-160 {
  margin-bottom: 160px;
}

.pot-margin-bottom-200 {
  margin-bottom: 200px;
}

.pot-margin-top-48-bottom-24 {
  margin-top: 48px;
  margin-bottom: 24px;
}

.pot-margin-top-10 {
  margin-top: 10px;
}

.pot-margin-top-20 {
  margin-top: 20px;
}

.pot-margin-top-30 {
  margin-top: 30px;
}

.pot-margin-top-40 {
  margin-top: 40px;
}

.pot-margin-top-50 {
  margin-top: 50px;
}

.pot-margin-top-60 {
  margin-top: 60px;
}

.pot-padding-bottom-10 {
  padding-bottom: 10px;
}

.pot-padding-bottom-20 {
  padding-bottom: 20px;
}

.pot-padding-bottom-30 {
  padding-bottom: 30px;
}

.pot-padding-bottom-40 {
  padding-bottom: 40px;
}

.pot-padding-bottom-50 {
  padding-bottom: 50px;
}

.pot-padding-bottom-60 {
  padding-bottom: 60px;
}

.pot-padding-top-10 {
  padding-top: 10px;
}

.pot-padding-top-20 {
  padding-top: 20px;
}

.pot-padding-top-30 {
  padding-top: 30px;
}

.pot-padding-top-40 {
  padding-top: 40px;
}

.pot-padding-top-50 {
  padding-top: 50px;
}

.pot-padding-top-60 {
  padding-top: 60px;
}
