@import "src/theme/theme-variables";

.pot-custom-form-error-msg {
  color: $red;
  padding-left: $paddingLeftCustomFormControl;
  padding-top: 3px;
  font-size: 12px;
}

.custom-form-control-container {
  width: 100%;
  position: relative;

  .custom-form-control-input {
    width: 100%;
    height: $heightCustomFormControl;
    padding: 10px $paddingLeftCustomFormControl;
    border-radius: $borderRadiusFormControl;
    outline: none;
    color: $silvery;

    &:focus {
      box-shadow: none;
    }
  }
}
