@import "src/theme/theme-variables";
@import "bootstrap/scss/mixins/breakpoints";

.modal-open {
  padding-right: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.modal {
  width: 100%;
  display: flex !important; /* stylelint-disable-line declaration-no-important */
  align-items: center;
  justify-content: center;

  .modal-dialog {
    max-width: $maxWidthComponents;
    margin-left: $paddingSidePage;
    margin-right: $paddingSidePage;
  }
}

.modal.animation-fade-from-bottom {
  opacity: 0;
  transition: all 0.2s linear;
  transform: translateY(100vh);

  .modal-dialog {
    transition: none;
    transform: none;
  }
}

.modal.animation-fade-from-bottom.show {
  opacity: 1;
  transform: translateY(0);
}
