@import "src/theme/theme-variables";

/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */
html {
  // scroll-behavior: smooth;
}

body {
  font: $defaultFont;
  overflow-x: hidden;
  line-height: 1.6;
}

p {
  margin-bottom: 0;
}

.modal-content {
  border-radius: $borderRadiusCard;
}

.container-sm {
  max-width: $maxWidthComponents;
  padding-left: 0;
  padding-right: 0;
}

.text-orange {
  color: $orange;
}

.grecaptcha-badge {
  visibility: hidden;
}
