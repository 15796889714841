@import "src/theme/theme-variables";
@import "bootstrap/scss/mixins/breakpoints";

.carousel-buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  user-select: none;

  > div {
    height: 50px;
    width: 50px;
    font-size: 40px;
    color: $white;
    background-color: rgba(255, 255, 255, 75%);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:first-of-type {
      margin-right: 10px;
    }

    &:last-of-type {
      margin-left: 10px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 65%);
    }

    &.disable {
      cursor: auto;
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
