/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
@import "https://fonts.googleapis.com/css?family=Montserrat:400,700";
@import "bootstrap/scss/bootstrap";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/brands";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/solid";

// Theme customization
@import "theme/theme";
@import "theme/typography";
@import "theme/custom-css-class";
@import "theme/carousel";
@import "theme/custom-control-form";
@import "theme/modal";
